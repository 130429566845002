import { handlePagination } from "../utils/handlePagination.js";
import { useContentRoute } from "../composables/pages/useContentRoute.js";
import {usePageMetadata} from "../composables/useMeta.js";

const localeStore = useLocaleStore();

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (to.params.code && from.params.code && localeStore.isLanguageSwitchNavigation) {
        localeStore.clearLanguageSwitch();
        return;
    }

    const pageName = to.params.code ? 'blogItem' : 'blogPage';

    const page = useState(pageName, () => {});
    const blogError = useState('error', () => '');

    const { data, error, fetchPageData } = useContentRoute('blog');

    const query = to.query;
    const params = ['page', 'perPage', 'search', 'tags']
        .filter((key) => query[key])
        .reduce((acc, key) => ({ ...acc, [key]: query[key] }), {});

    await fetchPageData(to, params);

    page.value = { ...data.value.page };
    blogError.value = error.value;
    usePageMetadata().initializePageMetadata(data.value);

    return handlePagination(to, () => page.value.pagination);
});
